<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalDeleteSpace.Header_ModalHeader')"
    confirmButtonCssClass="is-danger"
    :showModal="showModal"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickSave="saveReservation"
    :onClickCancel="onClickCancel"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        {{ $t('Components.Reservation.ModalDeleteSpace.Message_AreYouSure', {name: space.SpaceName}) }})
      </div>

      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="$t('Components.Reservation.ModalDeleteOption.Label_YesSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    space: null,

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveReservation() {
      let self = this

      self.reservation.Spaces.splice(self.space.Index, 1)

      if (!self.isDeleting) {
        self.isDeleting = true

        reservationProvider.methods
          .saveReservation(self.reservation, false)
          .then((response) => {
            if (response.status === 200) {
              self.setReservation(response.data)

              self.isDeletingSuccess = true

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Reservation is saved!',
              })
            }
          })
          .catch((error) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
